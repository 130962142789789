<template>
  <div class="py-5 flex flex-col min-h-screen" :class="background">
    <!-- Card  -->
    <div
      v-if="open_payment_link"
      class="m-auto w-11/12 max-w-full rounded-lg sm:max-w-4xl"
    >
      <!-- Payment Link Inactive  -->
      <div
        v-if="open_payment_link.status == false"
        class="p-10 flex flex-col items-center text-center"
      >
        <app-icon-invalid-link />

        <p class="text-3xl py-4">
          {{ $t("payment.open-payment-link.payment_page.link_inactive") }}
        </p>

        <p class="text-gray-400 py-4">
          {{
            $t(
              "payment.open-payment-link.payment_page.link_inactive_description"
            )
          }}
        </p>

        <p class="text-gray-400 py-1">
          {{
            $t("payment.open-payment-link.payment_page.link_inactive_support")
          }}
        </p>

        <p class="text-bluelink">
          {{ business.support_detail.support_email }}
        </p>
      </div>

      <!-- Payment Link Active  -->
      <div v-else class="flex md:flex-row flex-col justify-between">
        <!-- left Layout Payment -->
        <div
          class="
            py-2
            flex-1
            bg-gray-700
            flex flex-col
            rounded-none rounded-t-lg
            md:rounded-none md:rounded-l-lg
          "
        >
          <!-- image -->
          <div class="flex flex-col items-center p-4 space-y-6">
            <!-- Logo Business -->
            <img
              v-if="businessLogo"
              :src="businessLogo"
              class="w-32 object-cover"
            />
            <app-text-icon
              v-else
              :text="open_payment_link.business?.name"
              text_color="text-white"
              icon_outline="BriefcaseIcon"
              icon_color="text-white"
            />

            <!-- Banner -->
            <img
              v-if="openPaymentLinkBanner"
              :src="openPaymentLinkBanner"
              class="w-full object-contain"
            />
          </div>

          <!-- Amount to pay -->
          <div class="p-4 text-left bg-gray-600 space-y-1">
            <p class="text-white text-sm">
              {{ $t("payment.open-payment-link.payment_page.min_amount_pay") }}
            </p>
            <p class="text-white font-bold text-xl">
              {{
                  $formats.formattedCurrency(
                    open_payment_link.currency,
                    open_payment_link.formatted_minimum_amount ?? 0.0,
                  )
              }}
            </p>
          </div>

          <!-- Payment for -->
          <div class="p-4 text-left space-y-1">
            <p class="text-gray-400 text-sm">
              {{ $t("payment.open-payment-link.payment_page.payment_purpose") }}
            </p>
            <p class="text-white break-all">
              {{ open_payment_link.title ?? "-" }}
            </p>
          </div>

          <!-- Description -->
          <div class="p-4 text-left space-y-1">
            <p class="text-gray-400 text-sm">
              {{ $t("payment.open-payment-link.payment_page.description") }}
            </p>
            <app-text-readmore
              class="text-white"
              :text="open_payment_link.description ?? '-'"
            />
          </div>

          <div class="flex-grow" />

          <!-- Logo powered by Logo -->
          <div class="hidden md:flex justify-center space-x-2 items-center my-5">
            <!-- <div class="grid grid-cols-2 my-5 text-sm items-center"> -->
              <p class="text-white">{{ $t("general.powered_by") }}</p>
              <app-logo-cryptopay class="h-5" />
            <!-- </div> -->
          </div>
        </div>

        <!-- Right Layout Payment -->
        <div
          class="
            py-2
            flex-1
            bg-white
            space-y-5
            flex flex-col
            rounded-none rounded-b-lg
            md:rounded-none md:rounded-r-lg
          "
        >
          <!-- Payment Method -->
          <div class="p-6">
            <!-- Form Details -->
            <div class="space-y-3 py-6 text-left">
              <app-form-input-with-select class="w-full" type="number" step=".01"
                :labelText="$t('payment.create.amount_label')" :placeholder="$t('payment.create.amount_placeholder')"
                v-model="form_open_link.formatted_amount" v-model:option="form_open_link.currency" :errorMessage="errors.amount"
                :readonly="preview_mode" @input="validateAmount" maxLength="7" :options="[
                  {
                    id: 1,
                    desc: open_payment_link.currency ?? '-',
                  },
                ]"
              />

              <app-form-input
                type="email"
                :placeholder="$t('general.email')"
                :labelText="
                  $t('payment.open-payment-link.payment_page.email_address')
                "
                class="w-full"
                v-model="form_open_link.email"
                :errorMessage="errors.email"
              />

              <app-form-input
                type="text"
                :placeholder="$t('general.name')"
                :labelText="$t('payment.open-payment-link.payment_page.name')"
                class="w-full"
                v-model="form_open_link.name"
                :errorMessage="errors.name"
              />

              <app-form-telephone-no
                :labelText="
                  $t('payment.open-payment-link.payment_page.phone_no')
                "
                class="w-full"
                v-model="form_open_link.phone_no"
                :errorMessage="errors.phone_no"
              />
            </div>

            <!-- Proceed Payment Method -->
            <app-proceed-payment-method
              :payment_methods="payment_methods"
              @proceed_payment="proceedPayment"
              :preview_mode="preview_mode"
              :test_mode="testMode"
              :errors="errors"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Show No Results -->
    <div v-else class="m-auto w-11/12 max-w-full rounded-lg sm:max-w-4xl">
      <div
        class="p-20 bg-white rounded-lg flex flex-col items-center space-y-4"
      >
        <app-icon-invalid-link />
        <p class="text-xl">
          {{ $t("payment.open-payment-link.payment_page.link_invalid") }}
        </p>
      </div>
    </div>

    <!-- Logo powered by Logo -->
    <div
      class="flex justify-center items-center"
      :class="{ ' md:hidden': !hasOpenPaymentLink }"
    >
      <div class="flex justify-center space-x-2 items-center my-5 text-sm">
        <p :class="{ 'text-white': !hasOpenPaymentLink }">
          {{ $t("general.powered_by") }}
        </p>
        <app-logo-cryptopay class="h-5" />
      </div>
    </div>
  </div>
</template>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";

export default {
  data() {
    return {
      testMode: false,
      maxAmount: 30000,
      form_open_link: {
        name: null,
        email: null,
        phone_no: null,
        amount: null,
      },
    };
  },
  props: {
    preview_mode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["openPaymentLinkStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    businessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.LOGO
      )?.original_url;
    },

    payment_methods() {
      return this.$store.getters["paymentMethodStore/payment_methods"];
    },

    open_payment_link() {
      return this.$store.getters["openPaymentLinkStore/open_payment_link"];
    },

    openPaymentLinkBanner() {
      return this.open_payment_link?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.BANNER
      )?.original_url;
    },

    background() {
      return this.hasOpenPaymentLink ? "bg-white" : "bg-gray-800";
    },

    hasOpenPaymentLink() {
      return (
        this.open_payment_link?.status == false ||
        this.open_payment_link == null
      );
    },

    errors() {
      return this.$store.getters["openPaymentLinkStore/errors"];
    },
  },

  async mounted() {
    if (this.$route.query.id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }

    if (this.$route.query.test != null && this.$route.query.test == "true") {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);

    await this.getDataForPayment(this.$route.query.id);
  },

  methods: {
    async getDataForPayment(openPaymentLinkId) {
      let openPaymentLink = await this.$store.dispatch(
        "openPaymentLinkStore/getOpenPaymentLinkPublic",
        openPaymentLinkId
      );

      if (this.$lodash.isEmpty(openPaymentLink.data)) {
        this.$store.commit("openPaymentLinkStore/setOpenPaymentLink", null);
        return;
      }

      this.$store.commit(
        "businessStore/setBusiness",
        this.open_payment_link.business
      );

      await this.$store.dispatch(
        "paymentMethodStore/getPaymentMethods",
        this.business.id
      );
    },

    async proceedPayment(data) {
      if (
        this.previewMode ||
        this.open_payment_link._id == undefined ||
        data.business_payment_gateway_method_id == 0
      ) {
        return;
      }

      this.$store.commit("paymentStore/resetState", {});

      const paymentAttempt = await this.$store.dispatch(
        "openPaymentLinkStore/proceedOpenPaymentAttempt",
        {
          open_payment_link_id: this.open_payment_link._id,
          business_payment_gateway_method_id:
            data.business_payment_gateway_method_id,
          email: this.form_open_link.email,
          name: this.form_open_link.name,
          phone_no: this.form_open_link.phone_no,
          amount: this.form_open_link.amount,
          formatted_amount: this.form_open_link.formatted_amount,
          currency: this.open_payment_link.currency,
          card_info: data.card_info,
          bank_info: data.bank_info,
          stripe_create_payment_result: data.stripe_create_payment_result,
        }
      );

      if (paymentAttempt == null) {
        return;
      }

      if (paymentAttempt.status == "requires_action") {
        await data.stripe.confirmCardPayment(
          paymentAttempt.meta.intent.client_secret
        );
      }

      window.location.href = paymentAttempt.process_payment_url;
    },

    async getDataForPreview() {
      await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
      await this.$store.dispatch(
        "paymentMethodStore/getPaymentMethods",
        this.business.id
      );
    },

    validateAmount(event) {
      const amount = parseFloat(event.target.value?.trim() ?? 0);

      let maxAmount = this.maxAmount;
      let minAmount = parseFloat(this.open_payment_link.formatted_minimum_amount) ?? 0.0;

      if (amount > maxAmount) {
        this.errors.amount = [
          this.$t("payment.create.max_amount", {
            max_amount: this.$formats.formattedCurrency(
              this.open_payment_link?.currency,
              maxAmount
            ),
          }),
        ];
      } else if (amount < minAmount) {
        this.errors.amount = [
          this.$t("payment.create.min_amount", {
            min_amount: this.$formats.formattedCurrency(
              this.open_payment_link?.currency,
              minAmount
            ),
          }),
        ];
      } else {
        this.errors.amount = [];
      }
    },
  },
};
</script>